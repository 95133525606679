import React, { useEffect, useState, useRef } from "react";

import { useNavigate } from "react-router";
import styled from "styled-components";

import axios from "axios";

import { Tab, Tabs } from "react-bootstrap";

import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

import { IconContext } from "react-icons";
import { MdAttachFile } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import { RiCloseCircleLine } from "react-icons/ri";
import { RxMagicWand } from "react-icons/rx";

import { ProgressBar } from 'react-bootstrap';

import { Widget, addResponseMessage, addUserMessage, toggleMsgLoader } from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';

import { useTranslation } from 'react-i18next';

import './style/assistant.css';

import Playground from "../components/Playground";

let assistant_id;

const defaultFunctions = [{
                           "description": ""                           
                          },
                          {
                          "type": "function",
                          "description": "Google - Sugestão de Agenda",
                          "function":{
                                      "parent":null,
                                      "responseKey":null,
                                      "api":{
                                            "method": "POST",
                                            "url": "https://zaping.io:8445/google-suggestion/",                                         
                                            "headers":[
                                                        {
                                                        "Key": "quantidade",
                                                        "Value": "2"
                                                        }
                                                      ]
                                            },
                                      "description": "Função a ser executada para verificar os 2 próximos horários disponíveis.",
                                      "parameters":{
                                                    "type": "object",
                                                    "properties":{},
                                                    "required":[]
                                                  }
                                    }
                         },
                         {
                          "type": "function",
                          "description": "Google - Agendamento",
                          "function":{
                                      "parent":null,
                                      "responseKey":null,
                                      "api":{
                                            "method": "POST",
                                            "url": "https://zaping.io:8445/google-booking/"                                     
                                            },
                                      "description": "Função a ser executada para realizar agendamento.",
                                      "parameters":{
                                                    "type": "object",
                                                    "properties":{
                                                                  "agendar_horario":{
                                                                                    "type": "string",
                                                                                    "description": "Informe para quando quer agendar horário, exemplo: amanhã 14h"
                                                                                    },
                                                                  "nome_cliente":{
                                                                                  "type": "string",
                                                                                  "description": "Informe seu Nome, exemplo: João da Silva"
                                                                                }
                                                                },
                                                    "required":[]
                                                  }
                                    }
                         },
                         {
                          "type": "function",
                          "description": "Google - Disponibilidade de Agenda",
                          "function":{
                                      "parent":null,
                                      "responseKey":null,
                                      "api":{
                                            "method": "POST",
                                            "url": "https://zaping.io:8445/google-availability/"                                            
                                            },
                                      "description": "Função a ser executada para verificar a disponibilidade de horário para agendamento.",
                                      "parameters":{
                                                    "type": "object",
                                                    "properties":{
                                                                  "verificar_horario":{
                                                                                      "type": "string",
                                                                                      "description": "Informe para quando quer verificar a disponibilidade de horário na agenda."
                                                                                      }
                                                                },
                                                    "required":[]
                                                  }
                                    }
                        }
                        ]

export default function Assis() {
  const [openL, setOpen] = useState(false);

  const onToggleWidget = () => {
    setOpen(!openL);
  };

  const { t } = useTranslation();

  const functionDescriptionRef = useRef(null);
  const [chatWindowOpen, setChatWindowOpen] = useState(true);
  const [selectedFileField, setSelectedFileField] = useState("");
  const [isKeyDivVisible, setIsKeyDivVisible] = useState(false);

  const [loading, setLoading] = useState(true);
  const [inc, setInc] = useState();

  const navigate = useNavigate();          
                  
  const methodParams = [
                    {
                      "method": ""
                    },
                    {
                      "method": "GET"
                    },
                    {
                      "method": "POST"
                    },
                    {
                      "method": "PUT"
                    },
                    {
                      "method": "DELETE"
                    }
                  ]

  const parameterList =  [
    {
      "key": "Selecione o campo...",
      "type": "string",
      "description": "",
      "required": true
    },
    {
      "key": "nome_produto",
      "type": "string",
      "description": "Informe o nome do produto, exemplo: Brinco martelado",
      "required": false
    },
    {
      "key": "cpf_cnpj",
      "type": "string",
      "description": "Informe o CPF ou CNPJ, exemplo: 50126873200 or 51494125000101",
      "required": false
    },
    {
      "key": "nome_cliente",
      "type": "string",
      "description": "Informe seu Nome, exemplo: João da Silva",
      "required": true
    },
    {
      "key": "email",
      "type": "string",
      "description": "Informe o email, exemplo: joaosilva@gmail.com",
      "required": false
    },
    {
      "key": "shipping_email",
      "type": "string",
      "description": "Informe o email, exemplo: joaosilva@gmail.com",
      "required": false
    },
    {
      "key": "telefone",
      "type": "string",
      "description": "Informe o telefone, exemplo: 041999998888",
      "required": false
    },
    {
      "key": "cep",
      "type": "string",
      "description": "Informe o CEP, exemplo: 82025170",
      "required": false
    },
    {
      "key": "username",
      "type": "string",
      "description": "Informe o nome do usuário, exemplo: joaosilva",
      "required": false
    },
    {
      "key": "password",
      "type": "string",
      "description": "Informe a senha, exemplo: xptopassord1",
      "required": false
    },
    {
      "key": "conteudo",
      "type": "string",
      "description": "Informe o conteúdo a ser buscado.",
      "required": false
    },
    {
      "key": "empreendimento",
      "type": "string",
      "description": "Informe o empreendimento.",
      "required": false
    },
    {
      "key": "cidade",
      "type": "string",
      "description": "Informe a cidade.",
      "required": false
    },
    {
      "key": "finalidade",
      "type": "string",
      "description": "Informe a finalidade (moradia ou investimento).",
      "required": false
    },
    {
      "key": "forma_pagamento",
      "type": "string",
      "description": "Informe a forma de pagamento (à vista ou financiado).",
      "required": false
    },
    {
      "key": "valor",
      "type": "string",
      "description": "Informe o valor do investimento.",
      "required": false
    },
    {
      "key": "renda",
      "type": "string",
      "description": "Informe o valor da renda bruta.",
      "required": false
    },
    {
      "key": "compora_renda",
      "type": "string",
      "description": "Informe se comporá renda ou não.",
      "required": false
    },    
    {
      "key": "FGTS_entrada",
      "type": "string",
      "description": "Informe se utilizará FGTS ou valor de entrada.",
      "required": false
    },
    {
      "key": "agendado_para",
      "type": "string",
      "description": "Informe para quando foi agendado a visita.",
      "required": false
    },
    {
      "key": "verificar_horario",
      "type": "string",
      "description": "Informe para quando quer verificar a disponibilidade de horário.",
      "required": false
    },
    {
      "key": "agendar_horario",
      "type": "string",
      "description": "Informe para quando quer agendar horário.",
      "required": false
    },
    {
      "key": "cancelar_horario",
      "type": "string",
      "description": "Informe qual horário quer cancelar.",
      "required": false
    },
    {
      "key": "procedimento",
      "type": "string",
      "description": "Informe qual o procedimento desejado.",
      "required": false
    }
  ];
  
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [newFilesList, setNewFilesList] = useState([]);
  const [initialFiles, setInitialFiles] = useState([]);
  const [newAssistant, setNewAssistant] = useState([]);

  const [functionsList, setFunctionsList] = useState([]);
  const [stressFunction, setStressFunction] = useState({});
  const [filesList, setFilesList] = useState([]);
  const [functions, setFunctions] = useState([]);

  const [newFields, setNewFields] = useState({});
  const [currentApiKey, setCurrentApiKey] = useState({});

  const [isActive, setIsActive] = useState(false);
  const [detectStress, setDetectStress] = useState(false);

  const [keywords, setKeywords] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [oldInstructions, setOldInstructions] = useState('');

  const handleAddKeyword = (e) => {
    e.preventDefault();

    if (!inputValue.trim()) return; // Prevent adding empty strings
    setKeywords((prevItems) => [...prevItems, inputValue]);
    setInputValue(''); // Clear input after add
  };

  const handleRemoveKeyword = (e, indexToRemove) => {
    e.preventDefault();

    setKeywords((prevItems) => prevItems.filter((_, index) => index !== indexToRemove));
  };
  
  const changeFiles = (event) => {
    const files = event.target.files;
    setSelectedFiles([...selectedFiles, ...files]);
    setNewFilesList([...newFilesList, ...files]);
  };
  
  const [form, setForm] = useState({
    userId: "",
    companyArea: "",
    id: "",
    assistantName: "",
    alertPhone: "",
    instructions: "",
    tools: "",
    file_ids: ""
  });
  
  let userId = getStorage("_userId");

  function getStorage(prm) {
    let _prm = window.localStorage.getItem(prm);
    if(_prm === "null"){
      //navigate("/");
      //const newPort = 8445;
      //const newPath = '/';
      //window.location.href = `${window.location.protocol}//${window.location.hostname}:${newPort}${newPath}`;
    }else{
      if(_prm) {
        return _prm;
      }else{
        //navigate("/");
        //const newPort = 8445;
        //const newPath = '/';
        //window.location.href = `${window.location.protocol}//${window.location.hostname}:${newPort}${newPath}`;
      }
    }
  }
  
  // These methods will update the state properties.
  function updateForm(value) {
    return setForm((prev) => {
      return { ...prev, ...value };
    });
  }

  const updateFile = (index, updatedValue) => {
    let fileName = selectedFiles[index].name;
    const updatedFiles = [...selectedFiles]; // Create a copy of the selectedFiles array
    updatedFiles[index] = { ...updatedFiles[index], ...updatedValue }; // Update the specific file object with the new value
    updatedFiles[index] = { ...updatedFiles[index], ...{name: fileName} }; // Update the specific file object with the new value
    setSelectedFiles(updatedFiles); // Set the updatedFiles as the new state
  };

  const handleFileDelete = async (e, index) => {
    e.preventDefault();

    let file_id = selectedFiles[index].id;
    const newFiles = [...selectedFiles];
    newFiles.splice(index, 1);
    setSelectedFiles(newFiles);
  };  

  useEffect(() => {
    let func = functionsList?.map(item => item?.function?.name);
    setFunctions(func)
  }, [functionsList]);

  useEffect(() => {

    async function fetchData() {
      const response = await fetch('https://www.zaping.io:8445/query_assistant/' + userId);
      if (!response.ok) {
        toast.error('ERRO!');
        return;
      }
      let record = await response.json();
      if (!record) {
        toast.error('Usuário não Encontrado!', {autoClose: 3000});
        //navigate("/");
        const newPort = 8445;
        const newPath = '/';
        window.location.href = `${window.location.protocol}//${window.location.hostname}:${newPort}${newPath}`;    
        return;
      }else{      
        console.log(record)
        if (record?.gpt?.expireDate < Date.now()) {
          toast.error('Plano Expirado!', {closeOnClick: true});
        }

        setIsActive(record?.gpt?.active);
        setDetectStress(record?.gpt?.detectStress);

        setOldInstructions(record?.instructions);

        let keys = record?.gpt?.keywords;
        if(keys === '' || keys === null){
          setKeywords([])
        }else{
          if(typeof keys === 'string'){
            setKeywords(keys.split(','))
          }else{
            setKeywords(keys)
          }
        }

        let _functionsList;
        let _form;
        let isNew;
        let filesList = [];

        if(record?.gpt?.assistant_id){
          assistant_id = record?.gpt?.assistant_id;
          let files = record?.gpt?.files;
          isNew = false;
 
          let AllFunctionsList = record?.functionsList || [];   
          let stressFunc = AllFunctionsList.filter(item => item.function.name === 'stress');   
          stressFunc = stressFunc[0];
          setStressFunction(stressFunc);

          let functionsList = AllFunctionsList.filter(item => item.function.name !== 'stress');
          let filesList = []; // Initialize the filesList

          // Iterate through the functionsList in reverse to safely remove elements without affecting the iteration
          for (let i = functionsList.length - 1; i >= 0; i--) {
            const func = functionsList[i].function; // Current function object being iterated
        
            // Search through function's api.params
            func.api?.params?.forEach(param => {
              if (param?.Key === "collectionName") {
                // Find the matching file in files array using the Value of collectionName
                const fileMatch = files.find(file => file.id === param.Value);
                if (fileMatch) {
                  // If a matching file is found, prepare the filesList object
                  let filesListItem = {
                      function: func.name,
                      id: fileMatch.id,
                      name: fileMatch.name,
                      functionDescription: func.description,
                      field: "",
                      fieldDescription: ""
                  };
  
                  // Assuming there might be a specific param describing the product/customer etc.
                  func.api.params.forEach(p => {
                      if (p.Key !== "collectionName") { // Looking for a property different than "collectionName"
                          filesListItem.field = p.Key;
                          // Find the description for this param/field
                          if (func.parameters && func.parameters.properties && func.parameters.properties[p.Key]) {
                              filesListItem.fieldDescription = func.parameters.properties[p.Key].description;
                          }
                      }
                  });
  
                  // Add the constructed item to filesList
                  filesList.push(filesListItem);
  
                  // Remove the matched function object from the functionsList
                  functionsList.splice(i, 1);
                }
              }
            });
          }

          setSelectedFiles(filesList);
          setInitialFiles(filesList);             

          let APIs = record?.gpt?.api;
          const filteredAndMappedAPIs = APIs.filter(api => api.function.name !== "stress");

          const mergedArray = filteredAndMappedAPIs.map(apiItem => {
            // Find the matching item in functionList based on the function name
            const matchingFunctionItem = functionsList.find(funcListItem => funcListItem.function.name === apiItem.function.name);
            if (matchingFunctionItem) {
              // If a match is found, merge the properties of the objects
              return {
                function: {
                  ...apiItem.function,
                  ...matchingFunctionItem.function
                }
              };
            }
            // If no match is found, just return the original apiItem (edge case handling, can be adjusted based on requirements)
            return apiItem;
          });

          _functionsList = mergedArray; // Replace functionsList with the filtered list     
          _functionsList = _functionsList.filter(item => item.function.name.length !== 10);

          _form = {
              companyArea: record?.gpt?.companyArea || '',
              assistant_id: record.gpt.assistant_id,
              assistantName: record?.assistantName || '',
              active: record?.gpt?.active || '',
              alertPhone: record?.gpt?.alertPhone || '',
              keywords: record?.gpt?.keywords || '',
              instructions: record?.instructions || '',
            }
        }else{
          isNew = true;
          _functionsList = [];
          _form = {
            companyArea: '',
            assistant_id: null,
            assistantName: '',
            active: '',
            alertPhone: '',
            keywords: '',
            instructions: ''
          }
        }
        setNewAssistant(isNew);
        setForm(_form);

        setFunctionsList(_functionsList);
      }
    }

    fetchData();      

    return;
  }, [userId, navigate]);

  // This function will handle the submission.
  async function onSubmit(e) {
    e.preventDefault();

    let eleFocus;
    if(detectStress){
      if(form.alertPhone === '' || form.alertPhone === undefined){
        toast.error('Informe o número para alerta de transferência!', {autoClose: 3000});
        eleFocus = document.getElementById('alertPhone');
        eleFocus.focus();
        return;
      }
    }

    let keywordsList = keywords;
    if(keywords && keywords.length > 0){
      if(keywords.length === 1){
        if(keywords[0] === ''){
          keywordsList = [];
        }
      }else{
        if(keywords === null){
          keywordsList = [];
        }
        if(form.alertPhone === '' || form.alertPhone === undefined){
          toast.error('Informe o número para alerta de transferência!', {autoClose: 3000});
          eleFocus = document.getElementById('alertPhone');
          eleFocus.focus();
          return;
        }
      }
    }

    let finalFiles = [];
    selectedFiles?.map((file) => (                        
      finalFiles.push(file?.name)
    ))
    
    let notUpdateFiles = true;

    for (let i = 0; i < finalFiles.length; i++) {
      let found = false;
      for (const file in initialFiles) {
        if (finalFiles[i] === initialFiles[file].name) {
          found = true;
          break;
        }
      }
      if (!found) {
        notUpdateFiles = false;
        break;
      }
    }

    if(stressFunction){
      functionsList.push(stressFunction)
    }

    functionsList.forEach((item) => {
      const api = item?.function?.api;
  
      // Check if headers is an array and if all elements are null
      if (Array.isArray(api?.headers) && api?.headers?.every(val => val === null)) {
          delete api?.headers; // Delete the headers property if condition is met
      }
  
      // Apply the same logic to params and body
      if (Array.isArray(api?.params) && api?.params?.every(val => val === null)) {
          delete api?.params; // Delete the params property if condition is met
      }
  
      // Assuming there might be a body property implement similar logic
      if (Array.isArray(api?.body) && api?.body.every(val => val === null)) {
          delete api?.body; // Delete the body property if condition is met
      }
    });
    
    const getNextFunctionValue = (files) => {
      let maxFunctionNumber = 0;
      files.forEach(file => {
          if (file.function) {
              // Extract numeric part from the function string
              const functionNumber = parseInt(file.function.replace('function', ''), 10);
              if (functionNumber > maxFunctionNumber) {
                  maxFunctionNumber = functionNumber;
              }
          }
      });
      // Return the next function value by incrementing the highest number found
      return `function${maxFunctionNumber + 10}`;
    };
    
    let finalSelectedFiles = selectedFiles?.map(file => {
        // If "function" is not defined in the object, add it with the next available function value
        if (!file.function) {
            file.function = getNextFunctionValue(selectedFiles);
        }
        return file;
    });

    functionsList.forEach(func => {
      // Cleanse the 'body' if it exists
      if(func?.function?.api?.body) {
        func.function.api.body = func.function.api.body.filter(item => item != null && item !== "NULL" /* && other conditions */);
      }
      // Cleanse 'headers' if it exists
      if(func?.function?.api?.headers) {
        func.function.api.headers = func.function.api.headers.filter(header => header != null && header !== "NULL" /* && other conditions */);
      }
      // Cleanse 'params' if they exist
      if(func?.function?.api?.params) {
        func.function.api.params = func.function.api.params.filter(param => param != null && param !== "NULL" /* && other conditions */);
      }
    });

    let formData = new FormData(document.getElementById('formDataFile'));
    formData.append('assistantName', form.assistantName);
    formData.append('companyArea', form.companyArea);
    formData.append('alertPhone', form.alertPhone);
    formData.append('keywords', keywordsList);
    formData.append('detectStress', detectStress);
    
    formData.append('instructions', form.instructions);
    formData.append('newAssistant', newAssistant);
    if(form.assistant_id){
      formData.append('assistant_id', form.assistant_id);
    }
    formData.append('functionsList', JSON.stringify(functionsList));
    formData.append('filesList', JSON.stringify(finalSelectedFiles));
    formData.append('newFilesList', JSON.stringify(newFilesList));

    if(notUpdateFiles){
    } else {
      const newFiles = selectedFiles.filter(file => !initialFiles.some(initialFile => initialFile.name === file.name));
      formData.append('newFiles', JSON.stringify(newFiles));

      newFilesList.forEach((file) => {
        formData.append('file', file);
      });
    }   

    try {
      const response = await axios.post('https://www.zaping.io:8445/update_assistant_v2/' + userId, formData, {
          headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      toast.success("Assistente atualizado com sucesso!", {autoClose: 1000})
      setTimeout(() => {
        window.location.reload();
      }, 3000); // Delay in milliseconds (3000 ms = 3 seconds)
    } catch (error) {
      console.error('Error sending files:', error);
    }
  }

  const callMagic = async (e) => {
    e.preventDefault(); // Prevents default form submission
  
    if (form.briefing) {  
      let interval; // Declare interval here to increase its scope
      try {
        let data = JSON.stringify({
          "companyArea": form.companyArea,
          "briefing": form.briefing
        });
  
        let config = {
          method: 'post',
          url: `https://www.zaping.io:8445/magic_instructions/${userId}`,
          headers: { 
            'Content-Type': 'application/json'
          },
          data: data
        };
  
        setLoading(true);
        let start = 0;
        interval = setInterval(() => {
          start += 0.2;
          if (start > 100) {
            start = 100; // Update start if needed
            clearInterval(interval); // Ensure to clear when it reaches or exceeds 100
          }
          setInc(start);
        }, 100);
        
        const response = await axios(config);
        let newInstruction = response.data?.newInstruction;
        if(newInstruction){
          // Updating state with the new instructions
          form.instructions = newInstruction;
          toast.success("Instruções atualizadas! Favor checar.", {autoClose: 3000})

          onSubmit(e);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false); // Set loading to false in finally block
        clearInterval(interval); // Clear the interval here to ensure it's always done
      }
    }else{
      toast.error('Favor atualizar o Resumo antes de clicar!', {autoClose: 3000});
      const eleBriefing = document.getElementById('briefing');
      eleBriefing.focus();
    }
  };  

  const butAddNewFunction = (e) => {
    e.preventDefault(); // Prevents default form submission   

    let totalOfFunction = functionsList.length;    
    let functionName = "function" + totalOfFunction;
    const newFunction = {
                          type: "function",
                          function: {
                            name: functionName,
                            description: "",
                            parameters: {
                              type: "object",
                              properties: {                            
                              },
                              required: []
                            },
                            api: {}
                          }
                        }
  
    setFunctionsList([...functionsList, newFunction]);
    
    setTimeout(() => {
      if (functionDescriptionRef.current) {
        functionDescriptionRef.current.focus();
      }
    }, 500);
  };      

  const addNewField = (e, funcIndex) => {
    e.preventDefault();

    let field = "selField" + funcIndex;
    let eleToHide = document.getElementById(field);
    eleToHide.style.visibility = "visible";

    setTimeout(() => {
      eleToHide.click();
    }, 500);
  };

  const addNewApiParam = (e, funcIndex) => {
    e.preventDefault();

    let newApiParam = {
        Key: "",
        Value: ""
      }

    const updatedFunctionsList = [...functionsList];
    if (updatedFunctionsList[funcIndex]?.function?.api) {
      if (!updatedFunctionsList[funcIndex].function.api.params) {
        updatedFunctionsList[funcIndex].function.api.params = [];
      }
      updatedFunctionsList[funcIndex].function.api.params.push(newApiParam);
    } else {
      updatedFunctionsList[funcIndex].function.api = {
        params: [newApiParam]
      };
    }
    setFunctionsList(updatedFunctionsList);
  };

  const addNewApiHeader = (e, funcIndex) => {
    e.preventDefault();

    let newApiHeader = {
        Key: "",
        Value: ""
      }

    const updatedFunctionsList = [...functionsList];
    if (updatedFunctionsList[funcIndex]?.function?.api) {
      if (!updatedFunctionsList[funcIndex].function.api.headers) {
        updatedFunctionsList[funcIndex].function.api.headers = [];
      }
      updatedFunctionsList[funcIndex].function.api.headers.push(newApiHeader);
    } else {
      updatedFunctionsList[funcIndex].function.api = {
        headers: [newApiHeader]
      };
    }
    setFunctionsList(updatedFunctionsList);
  };

  const addNewApiBody = (e, funcIndex) => {
    e.preventDefault();

    let newApiBody = {
        Key: "",
        Value: ""
      }

    const updatedFunctionsList = [...functionsList];
    if (updatedFunctionsList[funcIndex]?.function?.api) {
      if (!updatedFunctionsList[funcIndex]?.function?.api?.body) {
        updatedFunctionsList[funcIndex].function.api.body = [];
      }
      updatedFunctionsList[funcIndex].function.api.body.push(newApiBody);
    } else {
      updatedFunctionsList[funcIndex].function.api = {
        body: [newApiBody]
      };
    }
    setFunctionsList(updatedFunctionsList);
  };

  const selectNewField = (funcIndex, e) => {
    let eleToHide = document.getElementById("selField" + funcIndex);
    eleToHide.style.visibility = "hidden";

    const selectedOption = parameterList.find(param => param.key === e.target.value);    

    let newField = {
      [e.target.value]: {
        type: "string",
        description: selectedOption ? selectedOption.description : "No description found"
      }
    }

    const updatedFunctionsList = [...functionsList];
    updatedFunctionsList[funcIndex].function.parameters.properties = {
      ...updatedFunctionsList[funcIndex].function.parameters.properties,
      ...newField
    };

    setFunctionsList(updatedFunctionsList);
  };

  const selectNewFileField = (e, index, updatedValue) => {
    const selectedOption = parameterList.find(param => param.key === e.target.value);    
  
    let newFileField = {
      [e.target.value]: {
        type: "string",
        description: selectedOption ? selectedOption.description : "No description found"
      }
    }
    document.getElementById("fileFieldDescription").value = selectedOption.description;

    let fileName = selectedFiles[index].name;
    let updatedFiles = [...selectedFiles]; // Create a copy of the selectedFiles array
    updatedFiles[index] = { ...updatedFiles[index], ...updatedValue }; // Update the specific file object with the new value
    updatedFiles[index] = { ...updatedFiles[index], ...{name: fileName, fieldDescription: selectedOption.description} }; // Update the specific file object with the new value
    setSelectedFiles(updatedFiles); // Set the updatedFiles as the new state
  };

  const updateFieldParameterKey = ({ index, key, newKey }) => {
    if (index in newFields) {
      setNewFields(prevState => ({
        ...prevState,
        [index]: {
          ...prevState[index],
          key: newKey
        }
      }));
    } else {
      // Handle update for existing fields
      // Write your logic to update existing field's key
    }
  };

  const updateFieldParameterValue = ({ index, key, newValue }) => {
    if (index in newFields) {
      setNewFields(prevState => ({
        ...prevState,
        [index]: {
          ...prevState[index],
          value: newValue
        }
      }));
    } else {
      const updatedFunctionsList = [...functionsList];
      updatedFunctionsList[index].function.parameters.properties[key].description = newValue;
      setFunctionsList(updatedFunctionsList);  
    }
  };

  const deleteFunction = (funcIndex, e) => {
    e.preventDefault();

    const updatedFunctionsList = [...functionsList];
    // Assuming funcIndex is the index of the function to delete from
    delete updatedFunctionsList[funcIndex];
    setFunctionsList(updatedFunctionsList);
  };

  const deleteField = (key, funcIndex, e) => {
    e.preventDefault();

    const updatedFunctionsList = [...functionsList];
    // Assuming funcIndex is the index of the function to delete from
    // You may want to verify if there are fields to delete, to prevent errors
    if (Object.keys(updatedFunctionsList[funcIndex].function.parameters.properties).length > 0) {
      delete updatedFunctionsList[funcIndex].function.parameters.properties[key];
      setFunctionsList(updatedFunctionsList);
    }
  };

  const deleteApiParam = (key, funcIndex, e) => {
    e.preventDefault();
    
    let updatedFunctionsList = [...functionsList];
    // Assuming funcIndex is the index of the function to delete from
    // You may want to verify if there are fields to delete, to prevent errors
    if (updatedFunctionsList[funcIndex].function.api.params[key]) {
      delete updatedFunctionsList[funcIndex].function.api.params[key];
      setFunctionsList(updatedFunctionsList);
    }
  };

  const deleteApiHeader = (key, funcIndex, e) => {
    e.preventDefault();
    
    let updatedFunctionsList = [...functionsList];
    // Assuming funcIndex is the index of the function to delete from
    // You may want to verify if there are fields to delete, to prevent errors
    if (updatedFunctionsList[funcIndex].function.api.headers[key]) {
      delete updatedFunctionsList[funcIndex].function.api.headers[key];
      setFunctionsList(updatedFunctionsList);
    }
  };

  const deleteApiBody = (key, funcIndex, e) => {
    e.preventDefault();
    console.log(key)
    console.log(funcIndex)
    let updatedFunctionsList = [...functionsList];
    // Assuming funcIndex is the index of the function to delete from
    // You may want to verify if there are fields to delete, to prevent errors
    console.log(updatedFunctionsList[funcIndex].function.api.body[key])
    if (updatedFunctionsList[funcIndex].function.api.body[key]) {
      delete updatedFunctionsList[funcIndex].function.api.body[key];
      setFunctionsList(updatedFunctionsList);
    }
  };

  const onUpdateApiMethod = (e, funcIndex) => {
    const newMethod = e.target.value;  
    let updatedFunctionsList = [...functionsList];
    updatedFunctionsList[funcIndex].function.api.method = newMethod ;
    setFunctionsList(updatedFunctionsList);
  };

  const onUpdateDefaultFunctions = (e) => {
    const newDefaultFunction = e.target.value;
    const totalOfFunction = functionsList.length;
    const funcName = "function" + totalOfFunction;
    let newDefaultFunc = {function: null};

    defaultFunctions?.map(selectedDefaultFunction => {
      // If "function" is not defined in the object, add it with the next available function value
      if (selectedDefaultFunction.description === newDefaultFunction) {
        newDefaultFunc.function = selectedDefaultFunction.function
      }
    });

    newDefaultFunc.function.name = funcName;
    newDefaultFunc.function.api.url = newDefaultFunc.function.api.url + userId;
    setFunctionsList([...functionsList, newDefaultFunc]);
    console.log([...functionsList, newDefaultFunc])
  };

  const onUpdateApiUrl = (e, funcIndex) => {
    const newUrl = e.target.value;
  
    let updatedFunctionsList = [...functionsList];    
    updatedFunctionsList[funcIndex].function.api.url = newUrl;
    setFunctionsList(updatedFunctionsList);
  };

  const onUpdateApiParamKey = (e, paramIndex, funcIndex) => {
    const newKey = e.target.value;
  
    const updatedFunctionsList = [...functionsList];

    // Check if paramIndex is greater than the length of the original params array
    if (paramIndex >= updatedFunctionsList[funcIndex].function.api.params.length) {
      // If it is a new insertion, update the Key value
      updatedFunctionsList[funcIndex].function.api.params.push({ Key: newKey, Value: '' });
    } else {
      // If it is an existing param, update the Key value
      updatedFunctionsList[funcIndex].function.api.params[paramIndex].Key = newKey;
    }
    setCurrentApiKey(newKey)
    setFunctionsList(updatedFunctionsList);
  }

  const onUpdateApiParamValue = (e, paramIndex, funcIndex) => {
    const newValue = e.target.value;
  
    const updatedFunctionsList = [...functionsList];

    // Check if paramIndex is greater than the length of the original params array
    if (paramIndex >= updatedFunctionsList[funcIndex].function.api.params.length) {
      // If it is a new insertion, update the Key value
      updatedFunctionsList[funcIndex].function.api.params.push({ Key: currentApiKey, Value: newValue });
    } else {
      // If it is an existing param, update the Key value
      updatedFunctionsList[funcIndex].function.api.params[paramIndex].Value = newValue;
    }
    setFunctionsList(updatedFunctionsList);
  }

  const onUpdateApiHeaderKey = (e, paramIndex, funcIndex) => {
    const newKey = e.target.value;
  
    const updatedFunctionsList = [...functionsList];

    // Check if paramIndex is greater than the length of the original params array
    if (paramIndex >= updatedFunctionsList[funcIndex].function.api.headers.length) {
      // If it is a new insertion, update the Key value
      updatedFunctionsList[funcIndex].function.api.headers.push({ Key: newKey, Value: '' });
    } else {
      // If it is an existing param, update the Key value
      updatedFunctionsList[funcIndex].function.api.headers[paramIndex].Key = newKey;
    }
    setCurrentApiKey(newKey)
    setFunctionsList(updatedFunctionsList);
  }

  const onUpdateApiHeaderValue = (e, paramIndex, funcIndex) => {
    const newValue = e.target.value;
  
    const updatedFunctionsList = [...functionsList];

    // Check if paramIndex is greater than the length of the original params array
    if (paramIndex >= updatedFunctionsList[funcIndex].function.api.headers.length) {
      // If it is a new insertion, update the Key value
      updatedFunctionsList[funcIndex].function.api.headers.push({ Key: currentApiKey, Value: newValue });
    } else {
      // If it is an existing param, update the Key value
      updatedFunctionsList[funcIndex].function.api.headers[paramIndex].Value = newValue;
    }
    setFunctionsList(updatedFunctionsList);
  }

  const onUpdateApiBodyKey = (e, paramIndex, funcIndex) => {
    const newKey = e.target.value;
  
    const updatedFunctionsList = [...functionsList];

    // Check if paramIndex is greater than the length of the original params array
    if (paramIndex >= updatedFunctionsList[funcIndex].function.api.body.length) {
      // If it is a new insertion, update the Key value
      updatedFunctionsList[funcIndex].function.api.body.push({ Key: newKey, Value: '' });
    } else {
      // If it is an existing param, update the Key value
      updatedFunctionsList[funcIndex].function.api.body[paramIndex].Key = newKey;
    }
    setCurrentApiKey(newKey)
    setFunctionsList(updatedFunctionsList);
  }

  const onUpdateApiBodyValue = (e, paramIndex, funcIndex) => {
    const newValue = e.target.value;
  
    const updatedFunctionsList = [...functionsList];

    // Check if paramIndex is greater than the length of the original params array
    if (paramIndex >= updatedFunctionsList[funcIndex].function.api.body.length) {
      // If it is a new insertion, update the Key value
      updatedFunctionsList[funcIndex].function.api.body.push({ Key: currentApiKey, Value: newValue });
    } else {
      // If it is an existing param, update the Key value
      updatedFunctionsList[funcIndex].function.api.body[paramIndex].Value = newValue;
    }
    setFunctionsList(updatedFunctionsList);
  }

  const handleToggleStatus = async (e) => {
    e.preventDefault();

    setIsActive(!isActive);
    
    let status;
    if(isActive){
      status = false;
    }else{
      status = true;
    }
    try {
      const data = await axios.post(`https://www.zaping.io:8445/change_user_status/${userId}/${status}`);
    } catch (error) {
      console.error(error);
    }
  };

  const handleToggleStress = async (e) => {
    e.preventDefault();

    setDetectStress(!detectStress);
    
    let stressStatus;
    if(detectStress){
      stressStatus = false;
      setStressFunction({});
    }else{
      stressStatus = true;

      const newStressFunction = {
                            type: "function",
                            function: {
                              name: 'stress',
                              description: "Função a ser executada quando o usuário estiver estressado.",
                              parameters: {
                                type: "object",
                                properties: {                            
                                },
                                required: []
                              },
                              api: {
                                      "method" : "POST",
                                      "url" : "https://www.zaping.io:8443/message/sendText/${instanceId}",
                                      "params" : [
                                          {
                                              "Key" : "instanceId",
                                              "Value" : userId
                                          }
                                      ],
                                      "headers" : [
                                        {
                                            "Key" : "Content-Type",
                                            "Value" : "application/json"
                                        }
                                      ],
                                      "body" : [
                                          {
                                              "Key" : "number",
                                              "Value" : ""
                                          }
                                      ]

                                    }

                            }
                          }                                 

      setStressFunction(newStressFunction);
    }
  };

  const Tooltip = ({ children, text }) => {
    return (
      <div className="tooltip-container">
        {children}
        <span className="tooltip-text">{text}</span>
      </div>
    );
  };

  const handleNewUserMessage = async (newMessage) => {
//    toggleMsgLoader();
    
    const messageContainer = document.getElementById('messages');

    let messageData = {
                       instance: userId,
                       type: "message",
                       origin: "test",
                       data: {
                              pushName: "",
                              key: { remoteJid: userId},
                              message: { conversation: newMessage }
                             }
                      }    

    try {
      let response = await fetch("https://www.zaping.io:8444/wa-new", {
        method: "POST",
        body: JSON.stringify(messageData),
        headers: {
          'Content-Type': 'application/json'
        },
      });
      if(response.status === 200){
        let apply = await response.json();
        if(apply?.text){
          addResponseMessage(apply.text);
        }
      }
    }
    catch (error) {
      console.error(error);
    }   
    
  };

  const handleToggleTest = async (open) => {
    await setChatWindowOpen((prev) => !prev);

    if(open){
      try {
        const data = await axios.get(`https://zaping.io:8445/clear/${userId}`);
        console.log(userId)
        const messages = document.querySelectorAll('#messages .rcw-message-text');
        const messageSet = new Set();
        messages.forEach((message) => {
          const text = message.innerText.trim();
          if (messageSet.has(text)) {
            message.parentNode.remove();
          } else {
            messageSet.add(text);
          }
        });

      } catch (error) {
        console.error(error);
      }
    }
  };

  // This following section will display the form that takes the input from the user.
  return (
    <Container>

      <div className="container-add">
        <div className="playground">
          <Playground />    
        </div>
        
        <div>
          <form onSubmit={onSubmit}>
            <div className="form-group">
              <div className="row">                               
                <div className="col-3">
                  <label htmlFor="assistantName">{t('assistantName')}</label>
                  <input
                    type="text"
                    className="form-control"
                    id="assistantName"
                    value={form.assistantName}
                    onChange={(e) => updateForm({ assistantName: e.target.value })}
                    placeholder="Ex: Yuri"
                  />
                  <br />
                </div>

                {!assistant_id ? (
                  <div className="col-7">
                    <label htmlFor="companyArea">{t('assistantFieldOfActivity')}</label>
                    <input
                      type="text"
                      className="form-control"
                      id="companyArea"
                      value={form.companyArea}
                      onChange={(e) => updateForm({ companyArea: e.target.value })}
                      placeholder="Ex: Ecommerce de semijoias exclusivas."
                    />
                    <br />
                  </div>
                ) : <div className="col-7"></div>}

                <div className="col-1">
                  <label htmlFor="butActiveDeactive">{t('assistantAutoresponse')}</label>
                  <Tooltip text="Clique para alternar.">
                    <button id="butActiveDeactive" onClick={handleToggleStatus} className={isActive ? 'butAct shadow1' : 'butDeact shadow1'}>
                      {isActive ? t('assistantActive') : t('assistantDeactive')}
                    </button>
                  </Tooltip>
                </div> 
              </div> 

              {!assistant_id ? (
                <div className="row">
                  <div className="col-10">
                    <label htmlFor="briefing">Resumo sobre seu Negócio</label>
                    <textarea
                      type="text"
                      className="form-control"
                      id="briefing"
                      value={form.briefing}
                      style={{height: '150px'}}
                      onChange={(e) => updateForm({ briefing: e.target.value })}
                      placeholder="Ex: A Yoru Jóias é um ecommerce de semijoias com modelos exclusivos. Atendemos todo o Brasil com frete grátis. Nosso site: https://www.yorujoias.com.br/" 
                    />
                    {loading && <ProgressBar style={{ backgroundColor: 'transparent' }} animated now={inc} />}
                  </div>

                  <div className="col-2">
                    <br />
                    <label htmlFor="butMagic">Clique gerar as Instruções</label>
                    <Tooltip text="Clique para a IA gerar as Instruções para seu negócio.">
                      <IconContext.Provider value={{ marginLeft: '10px', paddingLeft: '10px', size: '22px' }}>
                        <button id="butMagic" className="btn btn-primary shadow1" onClick={callMagic} style={{ marginLeft: '10px', paddingLeft: '10px' }} >
                          <RxMagicWand />
                        </button>                    
                      </IconContext.Provider>
                    </Tooltip>                
                  </div>
                </div>
              ) : null}

              <div className="row">
                <div className="col-12">
                  <br />
                  <label htmlFor="instructions">Instruções/Base de Conhecimento</label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="instructions"
                    value={form.instructions}
                    style={{height: '300px'}}
                    onChange={(e) => updateForm({ instructions: e.target.value })}
                    placeholder="Ex: Você é um assistente simpático e eficiente da Yoru Joias. Responda somente sobre o contexto da empresa..." 
                  />
                </div>

                <div className="col-12">
                  <div>
                    <form id="formDataFile" name="formDataFile" method="post" encType="multipart/form-data">
                      <label htmlFor="file" style={{backgroundColor: 'transparent !important'}}>
                        <Tooltip text="Clique para selecionar os arquivos.">
                            <IconContext.Provider value={{ color: "whitesmoke", size: '22px' }}>
                              <div className="customBorder shadow1">
                                <MdAttachFile />Adicionar (.csv, .json)
                                  <input type="file" 
                                        accept=".csv, .json"
                                        className="customBorder"
                                        id="file"
                                        name="file"
                                        onChange={changeFiles}
                                        style={{ display: "none" }}
                                  />
                              </div>
                            </IconContext.Provider>                              
                        </Tooltip>
                      </label>
                    </form>
                  </div>
                </div>
                
                <div className="col-12">

                    <ul style={{ color: "whitesmoke" }}>
                      {selectedFiles?.map((file, index) => (   
                        <div key={index}>
                        
                          <div className="row">                           
                            <div className="col-4" style={{ paddingLeft: '30px'}}>                                              
                              <li key={index}>
                                {file.name}
                                <Tooltip text="Clique para excluir o arquivo.">
                                  <button id="butFileDelete" onClick={(e) => handleFileDelete(e, index)}><RiCloseCircleLine /></button>
                                </Tooltip>
                              </li>
                            </div>
                            
                            <div className="col-8">                                              
                              <input
                                type="text"
                                className="form-control"
                                id="fileDescription"
                                value={file.functionDescription}
                                onChange={(e) => updateFile(index, { functionDescription: e.target.value })}                 
                                placeholder="ex: Função p/ buscar informações sobre produtos, quando for solicitado pelo usuário."
                              />  
                            </div>

                            <div className="col-1">
                            </div>
                            
                            <div className="col-3">
                              <select id="selFileField" onChange={ (e) => selectNewFileField(e, index, { field: e.target.value }) } className="form-control" value={file.field}>
                                {parameterList?.map((param) => (
                                  <option key={param.key} value={param.key}>{param.key}</option>
                                ))}
                              </select>
                            </div>

                            <div className="col-8">
                              <input
                                type="text"
                                className="form-control"
                                id="fileFieldDescription"
                                onChange={(e) => updateFile(index, { fieldDescription: e.target.value })}                 
                                value={file.fieldDescription}
                              />  
                            </div>
                            <br />
                            <br />
                          </div>
                        </div>

                      ))}
                    </ul>

                </div>
                <br />

                <div className="col-12">
                  
                  {functionsList?.map((_functionsList, funcIndex) => (
                    <div key={funcIndex}>
                      <div className="row">
                        <div className="col-3">
                          <label htmlFor="functionName">Função</label>
                          <input
                            type="text"
                            className="form-control"
                            id="functionName"
                            value={_functionsList?.function?.name}
                            disabled
                          />   
                        </div>
                        
                        <div className="col-1">
                          <Tooltip text="Clique para excluir a função.">
                            <br />
                            <button id="butDelFunction" onClick={(e) => deleteFunction(funcIndex, e)} style={{ borderColor: 'transparent', color: 'whiteSmoke', backgroundColor: 'transparent'}}>
                              <RiCloseCircleLine className="icon" />
                            </button>           
                          </Tooltip>
                        </div>

                        <div className="col-4">
                          <label htmlFor="parentFunction">Dependente da</label>
                          <select id="parentFunction" 
                                  onChange={(e) => {
                                      const updatedFunctionsList = [...functionsList];
                                      updatedFunctionsList[funcIndex].function.parent = e.target.value;
                                      setFunctionsList(updatedFunctionsList);
                                      setIsKeyDivVisible(true); // Update visibility state instead of direct DOM manipulation
                                  }}
                                  className="form-control" 
                                  value={_functionsList?.function?.parent}>
                                  <option value=""></option>
                                  {functions
                                      .filter((param) => param !== _functionsList?.function?.name) // Filter out the current function
                                      .map((param) => (
                                      <option key={param} value={param}>
                                          {param}
                                      </option>
                                  ))}
                          </select>                     
                        </div>  

                        <div className={`col-4 ${!isKeyDivVisible ? 'hidd' : ''}`} id="keyDiv">
                          <label htmlFor="functionDescription">Chave retorno</label>
                          <input
                            type="text"
                            className="form-control"
                            id="responseKey"
                            value={_functionsList?.function?.responseKey}
                            onChange={(e) => {
                              const updatedFunctionsList = [...functionsList];
                              updatedFunctionsList[funcIndex].function.responseKey = e.target.value;
                              setFunctionsList(updatedFunctionsList);                      
                            }}   
                            placeholder="Ex: token OU customer.name OU nome_cliente"
                          /> 
                        </div>                        
           
                        <div className="col-1">
                        </div>

                        <div className="col-11">
                          <label htmlFor="functionDescription">Descreva a API</label>
                          <input
                            type="text"
                            className="form-control"
                            id="functionDescription"
                            ref={functionDescriptionRef}
                            value={_functionsList?.function?.description}
                            onChange={(e) => {
                              const updatedFunctionsList = [...functionsList];
                              updatedFunctionsList[funcIndex].function.description = e.target.value;
                              setFunctionsList(updatedFunctionsList);
                            }}  
                            onBlur={() => {
                              document.getElementById('butAddField').click();
                            }}     
                            placeholder="ex: Função p/ obter informações sobre produtos quando for solicitado pelo usuário."
                          />   
                        </div>                                      
                      </div>

                      <div className="col-12">
                          <IconContext.Provider value={{ color: "whitesmoke", size: '22px' }}>
                            <Tooltip text="Clique para incluir campo.">
                              <button id="butAddField" onClick={(e) => addNewField(e, funcIndex)} className="butAddField"><IoMdAdd />Campo (ex: nome_produto, telefone, cpf_cnpj, nome_cliente, email, cep, conteudo)</button>
                            </Tooltip>
                          </IconContext.Provider>
                      </div>    

                      {Object.entries(_functionsList?.function?.parameters?.properties || {}).map(([key, value], propIndex) => (
                        <div key={key}>          
                          <div className="row">
                            <div className="col-1">
                              <Tooltip text="Clique para excluir o campo.">
                                <button onClick={(e) => deleteField(key, funcIndex, e)} style={{ borderColor: 'transparent', color: 'whiteSmoke', backgroundColor: 'transparent'}}>
                                  <RiCloseCircleLine className="icon" />
                                </button>           
                              </Tooltip>
                            </div>
                            <div className="col-3">
                              <input type="text" value={key} onChange={(e) => updateFieldParameterKey({ index: funcIndex, key, newKey: e.target.value })} className="form-control" />
                            </div>
                            <div className="col-8">
                              <input type="text" value={value.description} onChange={(e) => updateFieldParameterValue({ index: funcIndex, key, newValue: e.target.value })} className="form-control" />
                            </div>
                          </div>
                        </div>
                      ))}

                      {Object.entries(newFields).map(([index, field], newIndex) => (
                        <div key={index}>
                          <div className="row">
                            <div className="col-1">                              
                            </div>
                            <div className="col-3">
                              <input type="text" value={field.key} onChange={(e) => updateFieldParameterKey({ index, key: field.key, newKey: e.target.value })} className="form-control" />
                            </div>
                            <div className="col-8">
                              <input type="text" value={field.value} onChange={(e) => updateFieldParameterValue({ index, key: field.key, newValue: e.target.value })} className="form-control" />
                            </div>
                          </div>
                        </div>
                      ))}
    
                      <div className="row">
                        <div className="col-1">                              
                        </div>    
                        <div id={"selField" + funcIndex} className="col-3 hidd">
                          <select onChange={ (e) => selectNewField(funcIndex, e) } className="form-control" value={parameterList[0].key}>
                            {parameterList?.map((param, index) => (
                              <option key={param.key} value={param.key}>{param.key}</option>
                            ))}
                          </select>
                        </div>
                      </div>    

                      <br />

                      <div className="row">
                        <div className="col-3"> 
                          <Tooltip text="Clique para selecionar o método.">
                            <select
                              value={_functionsList?.function?.api?.method}
                              onChange={(e) => onUpdateApiMethod(e, funcIndex)} // Use onUpdateKey for Key update                                                     
                              style={{ fontSize: '100%' }}
                              className="form-control"
                            >  
                              {methodParams?.map((methodParams) => (
                                <option key={methodParams.method} value={methodParams.method}>{methodParams.method}</option>
                              ))}
                            </select>
                          </Tooltip>
                        </div>   

                        <div className="col-9"> 
                          <input
                            required
                            type="url"                            
                            className="form-control"
                            value={_functionsList?.function?.api?.url}
                            onChange={(e) => onUpdateApiUrl(e, funcIndex)}                                                
                            style={{fontSize: '100%'}}       
                            placeholder="Ex: https://www.yorujoias.com.br/produtos"
                            />   
                        </div>   

                        <div className="col-12"> 
                          <br />
                          <Tabs
                            defaultActiveKey="query-params"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                          >
                            <Tab eventKey="query-params" title="Params">
                              <div> 
                                {functionsList[funcIndex]?.function?.api?.params && Object.entries(functionsList[funcIndex]?.function?.api?.params).map(([paramIndex, param], propIndex) => (
                                  <div key={paramIndex}>
                                    <div className="row">
                                      <div className="col-1">
                                        <Tooltip text="Clique para excluir.">
                                          <button onClick={(e) => deleteApiParam(paramIndex, funcIndex, e)} style={{ borderColor: 'transparent', color: 'whiteSmoke', backgroundColor: 'transparent'}}>
                                            <RiCloseCircleLine className="icon" />
                                          </button>           
                                        </Tooltip>
                                      </div>
                                      <div className="col-3">
                                        <input type="text"
                                          value={param?.Key}
                                          onChange={(e) => onUpdateApiParamKey(e, paramIndex, funcIndex)} // Use onUpdateKey for Key update
                                          className="form-control" 
                                          placeholder="Informe a chave na API"
                                        />
                                      </div>
                                      <div className="col-8">
                                        <input type="text"
                                          value={param?.Value}
                                          onChange={(e) => onUpdateApiParamValue(e, paramIndex, funcIndex)} // Use onUpdateKey for Key update
                                          placeholder="Informe o conteúdo fixo ou deixe em branco para ser informado pelo usuário"
                                          className="form-control" 
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ))}                        
  
                                <div className="col-1">
                                  <Tooltip text="Clique para incluir parâmetro.">
                                    <IconContext.Provider value={{ color: "whitesmoke", size: '22px' }}>
                                      <button id="butAddApiParam" onClick={(e) => addNewApiParam( e, funcIndex )} className="butAddApiParam"><IoMdAdd /></button>
                                    </IconContext.Provider>
                                  </Tooltip>
                                </div>    
                              </div>                                
                            </Tab>

                            <Tab eventKey="query-headers" title="Headers">
                              <div> 
                                {functionsList[funcIndex]?.function?.api?.headers && Object.entries(functionsList[funcIndex]?.function?.api?.headers).map(([headersIndex, headers], propIndex) => (
                                  <div key={headersIndex}>
                                    <div className="row">
                                      <div className="col-1">
                                        <Tooltip text="Clique para excluir.">
                                          <button onClick={(e) => deleteApiHeader(headersIndex, funcIndex, e)} style={{ borderColor: 'transparent', color: 'whiteSmoke', backgroundColor: 'transparent'}}>
                                            <RiCloseCircleLine className="icon" />
                                          </button>           
                                        </Tooltip>
                                      </div>
                                      <div className="col-3">
                                        <input type="text"
                                          value={headers.Key}
                                          onChange={(e) => onUpdateApiHeaderKey(e, headersIndex, funcIndex)} // Use onUpdateKey for Key update
                                          className="form-control" 
                                          placeholder="Informe a chave na API"
                                        />
                                      </div>
                                      <div className="col-8">
                                        <input type="text"
                                          value={headers.Value}
                                          onChange={(e) => onUpdateApiHeaderValue(e, headersIndex, funcIndex)} // Use onUpdateKey for Key update
                                          placeholder="Informe o conteúdo fixo ou deixe em branco para ser informado pelo usuário"
                                          className="form-control" 
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ))}                        
  
                                <div className="col-1">
                                  <Tooltip text="Clique para incluir header.">
                                    <IconContext.Provider value={{ color: "whitesmoke", size: '22px' }}>
                                      <button id="butAddApiParam" onClick={(e) => addNewApiHeader( e,funcIndex )} className="butAddApiParam"><IoMdAdd /></button>
                                    </IconContext.Provider>
                                  </Tooltip>
                                </div>    
                              </div>                                
                            </Tab>

                            <Tab eventKey="query-body" title="Body">
                              <div> 
                                {functionsList[funcIndex]?.function?.api?.body && Object.entries(functionsList[funcIndex]?.function?.api?.body).map(([bodyIndex, body], propIndex) => (
                                  <div key={bodyIndex}>
                                    <div className="row">
                                      <div className="col-1">
                                        <Tooltip text="Clique para excluir.">
                                          <button onClick={(e) => deleteApiBody(bodyIndex, funcIndex, e)} style={{ borderColor: 'transparent', color: 'whiteSmoke', backgroundColor: 'transparent'}}>
                                            <RiCloseCircleLine className="icon" />
                                          </button>           
                                        </Tooltip>
                                        </div>
                                      <div className="col-3">
                                        <input type="text"
                                          value={body?.Key}
                                          onChange={(e) => onUpdateApiBodyKey(e, bodyIndex, funcIndex)} // Use onUpdateKey for Key update
                                          className="form-control" 
                                          placeholder="Informe a chave na API"
                                        />
                                      </div>
                                      <div className="col-8">
                                        <input type="text"
                                          value={body?.Value}
                                          onChange={(e) => onUpdateApiBodyValue(e, bodyIndex, funcIndex)} // Use onUpdateKey for Key update
                                          placeholder="Informe o conteúdo fixo ou deixe em branco para ser informado pelo usuário"
                                          className="form-control" 
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ))}                        
  
                                <div className="col-1">
                                  <Tooltip text="Clique para incluir body.">
                                    <IconContext.Provider value={{ color: "whitesmoke", size: '22px' }}>
                                      <button id="butAddApiParam" onClick={(e) => addNewApiBody( e,funcIndex )} className="butAddApiParam"><IoMdAdd /></button>
                                    </IconContext.Provider>
                                  </Tooltip>
                                </div>    
                              </div>                                
                            </Tab>

                          </Tabs>
                        </div>                             
                      </div>   
                      <div style={{color: 'whitesmoke'}}>
...........................................................................................................................................................................................................................
                      </div>   
                    </div>
                  ))}              
                </div>

                <div className="col-2">
                  <Tooltip text="Clique p/ incluir API.">
                    <IconContext.Provider value={{ color: "whitesmoke", size: '22px' }}>
                      <br />
                      <div className="customBorder shadow1">
                        <button id="butAddFunction" onClick={butAddNewFunction} className="butAddFunction"><IoMdAdd />API</button>                    
                      </div>                
                    </IconContext.Provider>
                  </Tooltip>
                </div>  

                <div className="col-7"> 
                  <Tooltip text="Selecione a API nativa">
                    <label htmlFor="defaultFunctionsList">Ou selecione a API nativa</label>
                    <select
                      id="defaultFunctionsList"
                      value={defaultFunctions?.description}
                      onChange={(d) => onUpdateDefaultFunctions(d)} // Use onUpdateKey for Key update                                                     
                      style={{ fontSize: '100%' }}
                      className="form-control"
                    >  
                      {defaultFunctions?.map((defaultFunctions) => (
                        <option key={defaultFunctions.description} value={defaultFunctions.description}>{defaultFunctions.description}</option>
                      ))}
                    </select>
                  </Tooltip>
                </div>   

              </div>
              <br />        

              <div className="row">    
                <div className="col-3">
                  <label htmlFor="alertPhone">Alerta transferência</label>
                  <input
                    type="text"
                    className="form-control"
                    id="alertPhone"
                    value={form.alertPhone}
                    onChange={(e) => updateForm({ alertPhone: e.target.value })}
                    placeholder="ex: 41987848151"
                  />
                </div>

                <div className="col-2">
                  <label htmlFor="butStress">Detecta Estresse</label>
                  <Tooltip text="Clique para alternar.">
                    <button id="butStress" onClick={handleToggleStress} className={detectStress ? 'butAct shadow1' : 'butDeact shadow1'}>
                      {detectStress ? 'Ativado' : 'Desativado'}
                    </button>
                  </Tooltip>
                </div> 
              </div>

              <div className="row">              
                <div className="col-3">
                  <br />      
                  <label htmlFor="butStress">Palavra-Chave</label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputKey"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && handleAddKeyword(e)}
                    placeholder="Digite e ENTER..."
                  />
                </div>

                <div className="col-9">
                  <br />
                  {keywords?.map((item, index) => (
                    <div key={index} className="item" style={{ display: 'inline-block', marginRight: '8px' }}>
                      <button type="button" class="btnTransp">{item}</button>
                      <button class="btnTransp" onClick={(e) => handleRemoveKeyword(e, index)}>X</button>
                    </div>
                  ))}
                </div>
              </div>

              <div>
                <br />
                <input
                  type="submit"
                  value="Salvar"
                  id="butSave"
                  className="btn btn-primary shadow1"
                />
              </div>

            </div>
          </form>
        </div>
        
        <div id="widget-container">
          <Widget
            id="butWidget"
            handleNewUserMessage={handleNewUserMessage}
            handleToggle={handleToggleTest}
            title={form.assistantName}
            subtitle=""
            autofocus="true"
            senderPlaceHolder="Pergunte algo..."
          />
        </div>
 
        <ToastContainer />

      </div>
    </Container>

  );
}

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  background-color: #212529; 

  .playground  {
    position: sticky; /* Set position to sticky */
    top: 0; /* Stick to the top */
    z-index: 999999999;
    height: 100vh; /* Set specific height */
    background-color: #212529;  
  }
  .container-add {
    overflow-y: scroll;
    height: 100vh;
    width: 100%;
    background-color: #212529;
    display: grid;
    grid-template-columns: 20% 80%; /* Use fractions of available space */
    @media screen  and (max-width: 1080px) {
      grid-template-columns: 5% 95%; /* Adjust for different screen size */
      font-size: 75%;
      #alertPhone {
        font-size: 100%;
      }
    }
  }
`;
